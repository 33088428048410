import React, { useState } from 'react';
import './BubbleMenu.css';
 
const BubbleMenu = ({ children, button }) => {
  
  const [isMenuOpen, setIsMenuOpen] = useState(false);
 
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className="bubble-menu-container">
      <div className="bubble-menu-button" onClick={toggleMenu}>
        {button}
      </div>
      {isMenuOpen && (
        <div className="bubble-menu">
          {children}
        </div>
      )}
    </div>
  );
};
 
export default BubbleMenu;