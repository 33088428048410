import React, { useState } from 'react';
import { Link } from 'react-scroll';
 
const DataEl = ({ text }) => {
  const [isTooltipActive, setIsTooltipActive] = useState(false);
 
  const toggleTooltip = () => {
    setIsTooltipActive(!isTooltipActive);
  };
 
  const handleDocumentClick = (event) => {
    if (!event.target.closest('[data-el="tooltip-button"]')) {
      setIsTooltipActive(false);
    }
  };
 
  React.useEffect(() => {
    isTooltipActive && document.addEventListener('click', handleDocumentClick);
    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, [isTooltipActive]);
 
  return (
    <div>
      <Link to="#" data-el="tooltip-button" onClick={toggleTooltip} className={isTooltipActive ? 'to_opened copied' : 'to_opened'}/>
    </div>
  );
};
 
export default DataEl;