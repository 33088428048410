import React, { useState, useEffect } from 'react';

function Countdown({t}) {
  // 设置目标时间，这里以未来的某个时间点为例
  const targetDate = new Date('2024-09-13T20:00:00').getTime();

  const [remainingTime, setRemainingTime] = useState(calculateRemainingTime(targetDate));

  // 计算剩余时间的函数
  function calculateRemainingTime(target) {
    const now = new Date().getTime();
    return target - now;
  }

  // 格式化时间的函数
  function formatTime(timeInMilliseconds) {
    const days = Math.floor(timeInMilliseconds / (1000 * 60 * 60 * 24));
    const hours = Math.floor((timeInMilliseconds % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((timeInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeInMilliseconds % (1000 * 60)) / 1000);
    if(days <= 0 && hours <= 0 && minutes <= 0 && seconds <= 0){
      return `0${t('day')} 0${t('hours')} 0${t('minutes')} 0${t('seconds')}`;
    }else{
      return `${days} ${t('day')} ${hours}${t('hours')} ${minutes}${t('minutes')} ${seconds}${t('seconds')}`;
    }
  }
  //生命周期钩子
  useEffect(() => {
    //intervalId 可用来卸载此组件
    const intervalId = setInterval(() => {
      setRemainingTime(calculateRemainingTime(targetDate));
    }, 1000);

    //组件卸载前执行clearInterval 清除定时器
    return () => clearInterval(intervalId);
  }, [targetDate]); //监听targetDate值改变就调用一次useEffect()

  return (
    <div>
      <p>{t('from_pre_time')} &nbsp; {formatTime(remainingTime)}</p>
    </div>
  );
}

export default Countdown;