import React, { useState,useRef,useEffect }  from 'react';
import Web3 from "web3";
import { Link, Button} from 'react-scroll';
import { Select} from "antd";
import {useTranslation} from "react-i18next";
import i18n from "./lang/i18n";
import BubbleMenu from './BubbleMenu';
import DataEl from './count/dataEl';
import Countdown from './Countdown';
import abi from './count/Abi.json';
import './App.css';


const {Option} = Select;

function App() {

  const [progresBar, setProgresBar] = useState('');

  //link組件
  const AnchorLink = ({ children, to }) => (
    <Link
      to={to}
      spy
      smooth
      offset={-20}
      duration={1000}
      className="pointer-cursor"
    >
      {children}
    </Link>
  );

  //button組件
  const AnchorButton = ({ children, to }) => (
    <Button
      to={to}
      spy
      smooth
      offset={-20}
      duration={1000}
    >
      {children}
    </Button>
  );

  //鏈接合約abi
  if (typeof window.ethereum!== 'undefined') {
    // 使用 MetaMask 提供的 provider
    var web3 = new Web3(window.ethereum);
  } else {
    // 否则，连接到别的节点
    var web3 = new Web3('https://bsc-dataseed.binance.org:443');
  }

  const switchToBNBChain = async () => {
    try {
      // Check if the user is connected to the right network
      const chainId = await web3.eth.getChainId();
      if (chainId !== 56) { // BNB mainnet chainId is 56
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: '0x38' }], // ChainId in hex for BNB mainnet
        });
      }
    } catch (error) {
      console.error('Failed to switch network:', error);
    }
  };
  
  const initialize = async () => {
    if (window.ethereum) {
      await switchToBNBChain();
      // Optionally, you could also add more logic here to handle wallet connection
    } else {
      alert(t('no_ethereum'));
    }
  };
  initialize();

  // const bnbTestnet = "https://data-seed-prebsc-1-s1.binance.org:8545/";
  // var web3 = new Web3(Web3.givenProvider || bnbTestnet);
  const mtcContract = new web3.eth.Contract(abi,'0x9E56d9887A69A94F3a9ce435687018a5581B41B5');
  
  // const [preSaleTokenTotal, setPreSaleTokenTotal] = useState('0');
  const [preSaleAmountSent, setPreSaleAmountSent] = useState('0');

  //翻譯組件
  const { t } = useTranslation();
  const [value,setValue] = useState('');
  //生命周期钩子
  useEffect(()=>{

    //查看是否存在默认语言
    const lang = localStorage.getItem('i18nextLng');
    if(lang){
        setValue(lang)
    }

    if (window.ethereum) {
      // 监听账户变化
      window.ethereum.on('accountsChanged', (accounts) => {
        // 更新应用的状态或界面
        if(accounts.length > 0){
          setaCcount(accounts[0]);
          getAccc(accounts[0]);
        }else{
          setaCcount('');
          getAccc('');
          setIsVisible(true);
        }
        
      });
    }

    setProgresBar('0%');
  },[]); //空数组谁也不监听
  
  const toWei = ( num )=> {
    return web3.utils.toWei(String(num), 'ether');
  }

  const fromWei = ( num )=> {
    return web3.utils.fromWei(String(num), 'ether');
  }

  //連接錢包
  const [account,setaCcount] = useState('');
  const [acct,setaCct] = useState('');
  //控制按鈕變換
  const [isVisible, setIsVisible] = useState(true);

  //显示短地址
  const getAccc = (acc)=>{
    const start = acc.substr(0, 4);
    const end = acc.substr(-4);
    setaCct(start+"***"+end);
  }

  const ConnectWallet = async()=> {
    //获取钱包授权链接
    try {
      const acc = await web3.eth.requestAccounts();
      setaCcount(acc[0]);
      getAccc(acc[0]);
      setIsVisible(false);
      //预售总量
      const preSaleTotal = await mtcContract.methods.preSaleTokenTotal().call();
      //已预售的的token数量
      const preSaleAmount = await mtcContract.methods.preSaleAmountSent().call();
      if(preSaleTotal !== 0){
        setProgresBar((String(preSaleAmount/preSaleTotal) * 100) + "%");
      }else{
        setProgresBar("100%");
      }
      setPreSaleAmountSent(fromWei(preSaleAmount).split('.').slice(0, -1).join('.'));
    } catch (error) {
      alert(t('no_wallet_app'));
    }
  }

  //web3調用购买合約
  const [preSalePrice, setPreSalePrice] = useState('0');
  
  const BuyTokenButton = async()=> {

    if(inputValue < 0.2 || inputValue > 10){
      alert(t('mix_buy_num'));
      return;
    }

    await window.ethereum.enable();
    const networkId = await web3.eth.net.getId();
    if(networkId !== 56){
      alert(t('switch_to_BNB'));
      return;
    }

    try{
      const presaleOpened = await mtcContract.methods.presaleOpened().call();
      const preSaleSoldOut = await mtcContract.methods.preSaleSoldOut().call();

      if(presaleOpened && !preSaleSoldOut){
        //转换为wei单位
        let weiAmount = toWei(inputValue);
        weiAmount = weiAmount.slice(0, -1) + "1";
        await mtcContract.methods.preSale()
        .send({
          from:account,
          value:weiAmount
        }) //监听receipt事件
        // .on('receipt', (res) => {
        //   console.log("购买成功!");
        //   debugger
        //   console.log(res);
        // })
        .on('transactionHash', (hash) => console.log(`Transaction Hash: ${hash}`)) //当交易被广播后，会触发这个事件，并返回交易的哈希。
        .on('receipt', (receipt) => console.log(`Receipt: ${JSON.stringify(receipt)}`)) //当交易被确认后，会触发这个事件，并返回交易的收据。
        .on('confirmation', (confirmationNumber, receipt) => console.log(`Confirmation: ${confirmationNumber}`)) //当交易获得一定数量的确认后，会触发这个事件。
        .on('error', (error) => console.error(`Error: ${error}`)); //如果交易过程中出现错误，会触发这个事件。
      }else{
        alert(t('sold_out'));
      }
      
    }catch(error){
      switch (error.code) {
        case 4001:
          alert(t('user_rej_tran'));
          break;
        case -32002:
          alert(t('request_exists'));
          break;
        case -32603:
          alert(t('error_occurred'));
          break;
        default:
          alert(t('unexpected_error'));
      }
      console.error('An error occurred:', error);
    }
  }

  //输入框只能输入数字或者小数验证
  const max = 10;
  const [inputValue, setInputValue] = useState('');
  // const inputRef = useRef(null);
  const isValidNumber = (number) => {
    return /^([1-9]\d*|0)(\.\d*)?$/.test(number);
  };

  //预售輸入框事件
  const HandleChange = async (event) => {
    const newValue = event.target.value;
    if(newValue === ''){
      setInputValue(newValue);
    }else{
      if (isValidNumber(newValue)) {
        setInputValue(newValue);
        setPreSalePrice(fromWei(await mtcContract.methods.preSalePrice().call()) * newValue);
      }
    }
  };

  //购买max事件
  const SetMax = () => {
    setInputValue(max);
    setPreSalePrice(max * 18000);
  }

  //质押第一个输入框验证
  const [inputPledge, setInputPledge] = useState('');
  const [inputProfit, setInputProfit] = useState('');
  const HandleChange2 = (event) => {
    const newValue = event.target.value;
    
    if(newValue === ''){
      setInputPledge(newValue);
    }else{
      if (isValidNumber(newValue)) {
        setInputPledge(newValue);
        if(newValue > 0){
          setInputProfit(newValue * 0.01);
        }
      }
    }
  };

  //质押MAX
  const HandleClick = async() => {
    if(account === ""){
      alert(t('con_the_wallet'));
      return;
    }

    try{
      const balanceOf = await mtcContract.methods.balanceOf(account).call();
      const balance = fromWei(balanceOf);
      setInputPledge(balance);
    }catch(error){
      console.log(error);
    }
  };

  //质押
  const ZhiYa = async() => {
    try{
      //转换为wei单位
      const weiPledge = toWei(inputPledge);
      const reast = await mtcContract.methods.stake(weiPledge)
      .send({
        from:account
      })
      .on('transactionHash', (hash) => console.log(`Transaction Hash: ${hash}`)) //当交易被广播后，会触发这个事件，并返回交易的哈希。
      .on('receipt', (receipt) => console.log(`Receipt: ${JSON.stringify(receipt)}`)) //当交易被确认后，会触发这个事件，并返回交易的收据。
      .on('confirmation', (confirmationNumber, receipt) => console.log(`Confirmation: ${confirmationNumber}`)) //当交易获得一定数量的确认后，会触发这个事件。
      .on('error', (error) => console.error(`Error: ${error}`)); //如果交易过程中出现错误，会触发这个事件。
      if(reast){
        alert(t('pledge_success'));
      }
      
    }catch(error){
      switch (error.code) {
        case 4001:
          alert(t('user_rej_tran'));
          break;
        case -32002:
          alert(t('request_exists'));
          break;
        case -32603:
          alert(t('error_occurred'));
          break;
        default:
          alert(t('pledge_failed'));
      }
      console.error('An error occurred:', error);
    }
  }

  //查询质押
  const [stakeNum, setStakeNum] = useState('0');
  const [stakeHours, setStakeHours] = useState('00:00');
  const SearchSDQ = async() => {
    try{
      const detailQuery = await mtcContract.methods.stakeDetailQuery(account).call();
      if(!detailQuery){
        alert(t('confirm_on_pledged'));
      }

      setStakeNum(fromWei(detailQuery[0]));

      const secs = (86400 - detailQuery[1]);
      let hours = Math.floor(secs / 3600);
      let minutes = Math.floor((secs % 3600) / 60);
      if(hours > 0 && minutes > 0){
        hours = hours < 10 ? '0' + hours : hours;
        minutes = minutes < 10 ? '0' + minutes : minutes;
        setStakeHours(hours + ':' + minutes);
      }
    }catch(error){
      switch (error.code) {
        case 4001:
          alert(t('user_rej_tran'));
          break;
        case -32002:
          alert(t('request_exists'));
          break;
        case -32603:
          alert(t('error_occurred'));
          break;
        default:
          alert(t('error_occurred'));
      }
      console.error('An error occurred:', error);
    }
  }

  //解除质押
  const ExitStake = async() => {
    try{
      const flg = await mtcContract.methods.exitStake().send({
        from:account
      }).on('transactionHash', (hash) => console.log(`Transaction Hash: ${hash}`)) //当交易被广播后，会触发这个事件，并返回交易的哈希。
      .on('receipt', (receipt) => console.log(`Receipt: ${JSON.stringify(receipt)}`)) //当交易被确认后，会触发这个事件，并返回交易的收据。
      .on('confirmation', (confirmationNumber, receipt) => console.log(`Confirmation: ${confirmationNumber}`)) //当交易获得一定数量的确认后，会触发这个事件。
      .on('error', (error) => console.error(`Error: ${error}`)); //如果交易过程中出现错误，会触发这个事件。

      if(flg){
        setStakeNum('0');
        alert(t('released_pledge'));
      }
    }catch(error){
      switch (error.code) {
        case 4001:
          alert(t('user_rej_tran'));
          break;
        case -32002:
          alert(t('request_exists'));
          break;
        case -32603:
          alert(t('error_occurred'));
          break;
        default:
          alert(t('no_zhiya_err'));
      }
    }
  }

  //切换medo
  const [pledge,setPledge] = useState('block');
  const [preSale,setPreSale] = useState('none');
  const [shortPosition,setshortPosition] = useState('none');
  const CardDisplay = ( flg )=> {
    return ()=>{
      if(flg === 2 ){
        setPledge('none');
        setPreSale('block');
        setshortPosition('none');
      }else if(flg === 3){
        setPledge('none');
        setPreSale('none');
        setshortPosition('block');
      }else{
        setPledge('block');
        setPreSale('none');
        setshortPosition('none');
      }
    }
  }

  //验证空投
  // const [inputTwitterUrl, setInputTwitterUrl] = useState('');
  const inputTwitterUrl = useRef('');
  // const getTwitterUrl = (event)=>{
  //   setInputTwitterUrl(event.target.value);
  // }
  const checkKoto = ()=> {
    if(inputTwitterUrl.current.value.trim() ===  ''){
      alert(t('forwarding_link'));
      return;
    }
    alert(t('get_koto'));
  }

  //子给父传值
  // const b = (data)=>{
  //   console.log(data);
  // }

  return (
    <div>
      <section id="hero">
        <div className="video-background">
        </div>
          <div className="nav-container">
            <div className="mb-nav">
              <div className="mb-nav-cont">
                <BubbleMenu button={<svg xmlns="http://www.w3.org/2000/svg"
                    width="20" height="17" viewBox="0 0 20 17" fill="none">
                    <path d="M1.5 1.5L18.5 1.5M1.5 8.5L18.5 8.5M1.5 15.5L18.5 15.5" stroke="white" strokeWidth="3"
                      strokeLinecap="round"></path>
                  </svg>}>
                  <AnchorButton to="how-to-buy">{t('how_to_buy')}<span className='bubble-span'><svg
                    xmlns="http://www.w3.org/2000/svg" width="20" height="22" viewBox="0 0 28 30" fill="none">
                    <path
                      d="M7.28568 5.71429V3.21429C7.28568 1.43909 8.72476 0 10.5 0H15.5C17.2751 0 18.7143 1.43909 18.7143 3.21429V5.71429H21.2142C23.7784 5.71429 25.8571 7.79296 25.8571 10.3571V17.8571C25.8571 16.0511 24.8997 14.4689 23.4647 13.5901C23.6245 13.2607 23.7142 12.8909 23.7142 12.5V10.3571C23.7142 8.97643 22.595 7.85714 21.2142 7.85714H4.78568C3.40496 7.85714 2.28568 8.97643 2.28568 10.3571V12.5C2.28568 13.8807 3.40496 15 4.78568 15H10.1428V14.2857C10.1428 13.4967 10.7824 12.8571 11.5714 12.8571H14.4285C15.2175 12.8571 15.8571 13.4967 15.8571 14.2857V15H16.7533C16.3174 15.625 16.0205 16.3543 15.9077 17.1429H15.8571C15.8571 17.9319 15.2175 18.5714 14.4285 18.5714H11.5714C10.7824 18.5714 10.1428 17.9319 10.1428 17.1429H4.78568C3.86522 17.1429 3.00732 16.875 2.28568 16.413V21.0714C2.28568 22.4521 3.40496 23.5714 4.78568 23.5714H13.4405C13.159 24.1156 13 24.7334 13 25.3886V25.5357C13 25.5953 13.001 25.6547 13.0028 25.7143H4.78568C2.22149 25.7143 0.142822 23.6356 0.142822 21.0714V10.3571C0.142822 7.79296 2.22149 5.71429 4.78568 5.71429H7.28568ZM15.5 2.14286H10.5C9.90824 2.14286 9.42854 2.62256 9.42854 3.21429V5.71429H16.5714V3.21429C16.5714 2.62256 16.0917 2.14286 15.5 2.14286ZM24.4285 17.8571C24.4285 19.8296 22.8295 21.4286 20.8571 21.4286C18.8847 21.4286 17.2857 19.8296 17.2857 17.8571C17.2857 15.8847 18.8847 14.2857 20.8571 14.2857C22.8295 14.2857 24.4285 15.8847 24.4285 17.8571ZM27.2857 25.5357C27.2857 27.7593 25.449 30 20.8571 30C16.2652 30 14.4285 27.7677 14.4285 25.5357V25.3886C14.4285 23.9899 15.5624 22.8571 16.961 22.8571H24.7533C26.1518 22.8571 27.2857 23.99 27.2857 25.3886V25.5357Z"
                      fill="white"></path>
                    </svg></span></AnchorButton>
                    <AnchorButton to="x_musk">{t('elon_musk')}<span className='bubble-span'><svg xmlns="http://www.w3.org/2000/svg"
                        width="24" height="24" viewBox="0 0 30 30" fill="none">
                        <path
                          d="M24.6875 5C26.2407 5 27.5 6.2592 27.5 7.8125V22.1933C27.5 23.7465 26.2407 25.0058 24.6875 25.0058H5.3125C3.7592 25.0058 2.5 23.7465 2.5 22.1933V7.8125C2.5 6.2592 3.7592 5 5.3125 5H24.6875ZM24.6875 6.875H5.3125C4.79474 6.875 4.375 7.29474 4.375 7.8125V22.1933C4.375 22.711 4.79474 23.1308 5.3125 23.1308H24.6875C25.2052 23.1308 25.625 22.711 25.625 22.1933V7.8125C25.625 7.29474 25.2052 6.875 24.6875 6.875ZM12.1875 15.625C12.7052 15.625 13.125 16.0448 13.125 16.5625V17.1784L13.1151 17.3141C12.9116 18.7053 11.7471 19.3761 9.99994 19.3761C8.25266 19.3761 7.0881 18.7046 6.88484 17.3126L6.875 17.1772V16.5625C6.875 16.0448 7.29474 15.625 7.8125 15.625H12.1875ZM16.5654 16.2451H22.1875C22.7052 16.2451 23.125 16.6649 23.125 17.1826C23.125 17.6573 22.7722 18.0495 22.3147 18.1116L22.1875 18.1201H16.5654C16.0476 18.1201 15.6279 17.7005 15.6279 17.1826C15.6279 16.708 15.9805 16.3158 16.4381 16.2537L16.5654 16.2451ZM10 10.6277C11.0355 10.6277 11.875 11.4672 11.875 12.5028C11.875 13.5382 11.0355 14.3776 10 14.3776C8.96449 14.3776 8.12504 13.5382 8.12504 12.5028C8.12504 11.4672 8.96449 10.6277 10 10.6277ZM16.5654 11.875H22.1875C22.7052 11.875 23.125 12.2947 23.125 12.8125C23.125 13.2871 22.7722 13.6794 22.3147 13.7415L22.1875 13.75H16.5654C16.0476 13.75 15.6279 13.3302 15.6279 12.8125C15.6279 12.3379 15.9805 11.9456 16.4381 11.8836L16.5654 11.875Z"
                          fill="white"></path>
                      </svg></span></AnchorButton>
                  <AnchorButton to="about-bitsdog">{t('about')}<span className='bubble-span'><svg xmlns="http://www.w3.org/2000/svg"
                        width="24" height="24" viewBox="0 0 30 30" fill="none">
                        <path
                          d="M15.0002 2.49756C21.9049 2.49756 27.5021 8.09486 27.5021 14.9995C27.5021 21.9041 21.9049 27.5014 15.0002 27.5014C8.09559 27.5014 2.49829 21.9041 2.49829 14.9995C2.49829 8.09486 8.09559 2.49756 15.0002 2.49756ZM15.0002 4.37256C9.13113 4.37256 4.37329 9.1304 4.37329 14.9995C4.37329 20.8686 9.13113 25.6264 15.0002 25.6264C20.8694 25.6264 25.6271 20.8686 25.6271 14.9995C25.6271 9.1304 20.8694 4.37256 15.0002 4.37256ZM14.9956 13.1232C15.4702 13.123 15.8627 13.4754 15.9251 13.933L15.9337 14.0601L15.9382 20.9371C15.9386 21.4549 15.5191 21.875 15.0014 21.8752C14.5267 21.8756 14.1342 21.5231 14.0719 21.0656L14.0632 20.9384L14.0587 14.0614C14.0585 13.5436 14.4779 13.1236 14.9956 13.1232ZM15.0007 8.75107C15.6902 8.75107 16.2491 9.30997 16.2491 9.99941C16.2491 10.6888 15.6902 11.2477 15.0007 11.2477C14.3114 11.2477 13.7525 10.6888 13.7525 9.99941C13.7525 9.30997 14.3114 8.75107 15.0007 8.75107Z"
                          fill="white"></path>
                      </svg></span></AnchorButton>
                  <AnchorButton to="tokenomics">{t('tokenomics')} <span className='bubble-span'><svg
                        xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 30 30" fill="none">
                        <path
                          d="M16.25 14.6875H24.6432C25.0686 14.2536 25.6195 13.9433 26.237 13.8151C25.9447 7.51671 20.7458 2.5 14.375 2.5C7.81661 2.5 2.5 7.81661 2.5 14.375C2.5 20.7237 7.48215 25.9089 13.75 26.2339V24.375V24.3558C8.51832 24.0333 4.375 19.688 4.375 14.375C4.375 8.9567 8.68425 4.54489 14.0625 4.37979V12.5C14.0625 13.7081 15.0419 14.6875 16.25 14.6875ZM16.25 12.8125C16.0774 12.8125 15.9375 12.6726 15.9375 12.5V4.49635C20.2091 5.1666 23.5834 8.54094 24.2536 12.8125H16.25ZM26.875 15C25.8395 15 25 15.8395 25 16.875V26.875C25 27.9105 25.8395 28.75 26.875 28.75C27.9105 28.75 28.75 27.9105 28.75 26.875V16.875C28.75 15.8395 27.9105 15 26.875 15ZM16.875 22.5C15.8395 22.5 15 23.3395 15 24.375V26.875C15 27.9105 15.8395 28.75 16.875 28.75C17.9105 28.75 18.75 27.9105 18.75 26.875V24.375C18.75 23.3395 17.9105 22.5 16.875 22.5ZM20 20.625C20 19.5895 20.8395 18.75 21.875 18.75C22.9105 18.75 23.75 19.5895 23.75 20.625V26.875C23.75 27.9105 22.9105 28.75 21.875 28.75C20.8395 28.75 20 27.9105 20 26.875V20.625Z"
                          fill="white"></path>
                      </svg></span></AnchorButton>
                  <AnchorButton to="support">{t('support')}<span className='bubble-span'><svg xmlns="http://www.w3.org/2000/svg"
                        width="24" height="24" viewBox="0 0 30 30" fill="none">
                        <path
                          d="M13.9801 2.5C13.2723 2.5 12.6529 2.97591 12.4704 3.6599L6.61308 25.625H3.4375C2.91974 25.625 2.5 26.0448 2.5 26.5625C2.5 27.0802 2.91974 27.5 3.4375 27.5H26.5625C27.0802 27.5 27.5 27.0802 27.5 26.5625C27.5 26.0448 27.0802 25.625 26.5625 25.625H23.3869L17.5296 3.6599C17.3471 2.97591 16.7277 2.5 16.0199 2.5H13.9801ZM8.5536 25.625L9.72026 21.25H17.1875C17.7052 21.25 18.125 20.8302 18.125 20.3125C18.125 19.7948 17.7052 19.375 17.1875 19.375H10.2203L10.8869 16.875H15.9375C16.4552 16.875 16.875 16.4552 16.875 15.9375C16.875 15.4198 16.4552 15 15.9375 15H11.3869L14.2202 4.375H15.7798L21.4464 25.625H8.5536Z"
                          fill="white"></path>
                      </svg></span></AnchorButton>
                </BubbleMenu>
                <Select className='custom-select' value={value} onChange={(value)=>{
                        i18n.changeLanguage(value);
                        setValue(value)
                      }}>
                        <Option value={'en'}>English</Option>
                        <Option value={'cn'}>中文</Option>
                    </Select>
                <a href="#" onClick={ConnectWallet}><span className="uppercase">{account ? acct: t('connect_wallet')}</span></a>
              </div>
            </div>
            <nav>
              <ul>
                <li><a href="#"><img src="/img/button_kong.png" alt="Black Goku"/></a></li>
                <li><AnchorLink to="how-to-buy">{t('how_to_buy')}<span><svg
                  xmlns="http://www.w3.org/2000/svg" width="28" height="30" viewBox="0 0 28 30" fill="none">
                  <path
                    d="M7.28568 5.71429V3.21429C7.28568 1.43909 8.72476 0 10.5 0H15.5C17.2751 0 18.7143 1.43909 18.7143 3.21429V5.71429H21.2142C23.7784 5.71429 25.8571 7.79296 25.8571 10.3571V17.8571C25.8571 16.0511 24.8997 14.4689 23.4647 13.5901C23.6245 13.2607 23.7142 12.8909 23.7142 12.5V10.3571C23.7142 8.97643 22.595 7.85714 21.2142 7.85714H4.78568C3.40496 7.85714 2.28568 8.97643 2.28568 10.3571V12.5C2.28568 13.8807 3.40496 15 4.78568 15H10.1428V14.2857C10.1428 13.4967 10.7824 12.8571 11.5714 12.8571H14.4285C15.2175 12.8571 15.8571 13.4967 15.8571 14.2857V15H16.7533C16.3174 15.625 16.0205 16.3543 15.9077 17.1429H15.8571C15.8571 17.9319 15.2175 18.5714 14.4285 18.5714H11.5714C10.7824 18.5714 10.1428 17.9319 10.1428 17.1429H4.78568C3.86522 17.1429 3.00732 16.875 2.28568 16.413V21.0714C2.28568 22.4521 3.40496 23.5714 4.78568 23.5714H13.4405C13.159 24.1156 13 24.7334 13 25.3886V25.5357C13 25.5953 13.001 25.6547 13.0028 25.7143H4.78568C2.22149 25.7143 0.142822 23.6356 0.142822 21.0714V10.3571C0.142822 7.79296 2.22149 5.71429 4.78568 5.71429H7.28568ZM15.5 2.14286H10.5C9.90824 2.14286 9.42854 2.62256 9.42854 3.21429V5.71429H16.5714V3.21429C16.5714 2.62256 16.0917 2.14286 15.5 2.14286ZM24.4285 17.8571C24.4285 19.8296 22.8295 21.4286 20.8571 21.4286C18.8847 21.4286 17.2857 19.8296 17.2857 17.8571C17.2857 15.8847 18.8847 14.2857 20.8571 14.2857C22.8295 14.2857 24.4285 15.8847 24.4285 17.8571ZM27.2857 25.5357C27.2857 27.7593 25.449 30 20.8571 30C16.2652 30 14.4285 27.7677 14.4285 25.5357V25.3886C14.4285 23.9899 15.5624 22.8571 16.961 22.8571H24.7533C26.1518 22.8571 27.2857 23.99 27.2857 25.3886V25.5357Z"
                    fill="white"></path>
                </svg></span></AnchorLink></li>
                <li><AnchorLink to="x_musk">{t('elon_musk')}<span><svg xmlns="http://www.w3.org/2000/svg"
                    width="30" height="30" viewBox="0 0 30 30" fill="none">
                    <path
                      d="M24.6875 5C26.2407 5 27.5 6.2592 27.5 7.8125V22.1933C27.5 23.7465 26.2407 25.0058 24.6875 25.0058H5.3125C3.7592 25.0058 2.5 23.7465 2.5 22.1933V7.8125C2.5 6.2592 3.7592 5 5.3125 5H24.6875ZM24.6875 6.875H5.3125C4.79474 6.875 4.375 7.29474 4.375 7.8125V22.1933C4.375 22.711 4.79474 23.1308 5.3125 23.1308H24.6875C25.2052 23.1308 25.625 22.711 25.625 22.1933V7.8125C25.625 7.29474 25.2052 6.875 24.6875 6.875ZM12.1875 15.625C12.7052 15.625 13.125 16.0448 13.125 16.5625V17.1784L13.1151 17.3141C12.9116 18.7053 11.7471 19.3761 9.99994 19.3761C8.25266 19.3761 7.0881 18.7046 6.88484 17.3126L6.875 17.1772V16.5625C6.875 16.0448 7.29474 15.625 7.8125 15.625H12.1875ZM16.5654 16.2451H22.1875C22.7052 16.2451 23.125 16.6649 23.125 17.1826C23.125 17.6573 22.7722 18.0495 22.3147 18.1116L22.1875 18.1201H16.5654C16.0476 18.1201 15.6279 17.7005 15.6279 17.1826C15.6279 16.708 15.9805 16.3158 16.4381 16.2537L16.5654 16.2451ZM10 10.6277C11.0355 10.6277 11.875 11.4672 11.875 12.5028C11.875 13.5382 11.0355 14.3776 10 14.3776C8.96449 14.3776 8.12504 13.5382 8.12504 12.5028C8.12504 11.4672 8.96449 10.6277 10 10.6277ZM16.5654 11.875H22.1875C22.7052 11.875 23.125 12.2947 23.125 12.8125C23.125 13.2871 22.7722 13.6794 22.3147 13.7415L22.1875 13.75H16.5654C16.0476 13.75 15.6279 13.3302 15.6279 12.8125C15.6279 12.3379 15.9805 11.9456 16.4381 11.8836L16.5654 11.875Z"
                      fill="white"></path>
                  </svg></span></AnchorLink></li>
                <li><AnchorLink to="about-bitsdog">{t('about')}<span><svg xmlns="http://www.w3.org/2000/svg"
                        width="30" height="30" viewBox="0 0 30 30" fill="none">
                        <path
                          d="M15.0002 2.49756C21.9049 2.49756 27.5021 8.09486 27.5021 14.9995C27.5021 21.9041 21.9049 27.5014 15.0002 27.5014C8.09559 27.5014 2.49829 21.9041 2.49829 14.9995C2.49829 8.09486 8.09559 2.49756 15.0002 2.49756ZM15.0002 4.37256C9.13113 4.37256 4.37329 9.1304 4.37329 14.9995C4.37329 20.8686 9.13113 25.6264 15.0002 25.6264C20.8694 25.6264 25.6271 20.8686 25.6271 14.9995C25.6271 9.1304 20.8694 4.37256 15.0002 4.37256ZM14.9956 13.1232C15.4702 13.123 15.8627 13.4754 15.9251 13.933L15.9337 14.0601L15.9382 20.9371C15.9386 21.4549 15.5191 21.875 15.0014 21.8752C14.5267 21.8756 14.1342 21.5231 14.0719 21.0656L14.0632 20.9384L14.0587 14.0614C14.0585 13.5436 14.4779 13.1236 14.9956 13.1232ZM15.0007 8.75107C15.6902 8.75107 16.2491 9.30997 16.2491 9.99941C16.2491 10.6888 15.6902 11.2477 15.0007 11.2477C14.3114 11.2477 13.7525 10.6888 13.7525 9.99941C13.7525 9.30997 14.3114 8.75107 15.0007 8.75107Z"
                          fill="white"></path>
                      </svg></span></AnchorLink></li>
                <li><AnchorLink to="tokenomics">{t('tokenomics')}<span><svg
                        xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                        <path
                          d="M16.25 14.6875H24.6432C25.0686 14.2536 25.6195 13.9433 26.237 13.8151C25.9447 7.51671 20.7458 2.5 14.375 2.5C7.81661 2.5 2.5 7.81661 2.5 14.375C2.5 20.7237 7.48215 25.9089 13.75 26.2339V24.375V24.3558C8.51832 24.0333 4.375 19.688 4.375 14.375C4.375 8.9567 8.68425 4.54489 14.0625 4.37979V12.5C14.0625 13.7081 15.0419 14.6875 16.25 14.6875ZM16.25 12.8125C16.0774 12.8125 15.9375 12.6726 15.9375 12.5V4.49635C20.2091 5.1666 23.5834 8.54094 24.2536 12.8125H16.25ZM26.875 15C25.8395 15 25 15.8395 25 16.875V26.875C25 27.9105 25.8395 28.75 26.875 28.75C27.9105 28.75 28.75 27.9105 28.75 26.875V16.875C28.75 15.8395 27.9105 15 26.875 15ZM16.875 22.5C15.8395 22.5 15 23.3395 15 24.375V26.875C15 27.9105 15.8395 28.75 16.875 28.75C17.9105 28.75 18.75 27.9105 18.75 26.875V24.375C18.75 23.3395 17.9105 22.5 16.875 22.5ZM20 20.625C20 19.5895 20.8395 18.75 21.875 18.75C22.9105 18.75 23.75 19.5895 23.75 20.625V26.875C23.75 27.9105 22.9105 28.75 21.875 28.75C20.8395 28.75 20 27.9105 20 26.875V20.625Z"
                          fill="white"></path>
                      </svg></span></AnchorLink></li>
                <li><AnchorLink to="support">{t('support')}<span><svg xmlns="http://www.w3.org/2000/svg"
                        width="30" height="30" viewBox="0 0 30 30" fill="none">
                        <path
                          d="M13.9801 2.5C13.2723 2.5 12.6529 2.97591 12.4704 3.6599L6.61308 25.625H3.4375C2.91974 25.625 2.5 26.0448 2.5 26.5625C2.5 27.0802 2.91974 27.5 3.4375 27.5H26.5625C27.0802 27.5 27.5 27.0802 27.5 26.5625C27.5 26.0448 27.0802 25.625 26.5625 25.625H23.3869L17.5296 3.6599C17.3471 2.97591 16.7277 2.5 16.0199 2.5H13.9801ZM8.5536 25.625L9.72026 21.25H17.1875C17.7052 21.25 18.125 20.8302 18.125 20.3125C18.125 19.7948 17.7052 19.375 17.1875 19.375H10.2203L10.8869 16.875H15.9375C16.4552 16.875 16.875 16.4552 16.875 15.9375C16.875 15.4198 16.4552 15 15.9375 15H11.3869L14.2202 4.375H15.7798L21.4464 25.625H8.5536Z"
                          fill="white"></path>
                      </svg></span></AnchorLink></li>
                <li><Select className='custom-select' value={value} onChange={(value)=>{
                        i18n.changeLanguage(value);
                        setValue(value)
                      }}>
                        <Option value={'en'}>English</Option>
                        <Option value={'cn'}>中文</Option>
                    </Select></li>
                <li>
                  <a href="#"  onClick={ConnectWallet}>
                    <span className="uppercase" style={{fontSize: "1.4vw"}}>{account ? acct: t('connect_wallet')}</span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
          <h1 className="text-white"><span>{t('black_goku')}</span>
          </h1>
          <p className="text-center text-white">{t('welcome')}</p>
          <div className="join-community">
            <h2 className="pt-0 text-center text-[calc(32/1920*100vw)] uppercase text-white">{t('community')}</h2>
            <ul>
              <li>
                <a href="https://x.com/BlacGoku_" target="_blank"></a>
              </li>
              <li>
                <a href="https://t.me/+8pBIdAUjSeAzNGQ9" target="_blank"></a>
              </li>
              <li>
                {/* <a href="#" target="_blank" className="to_opened"></a> */}
                {/* <DataEl text={b}/> */}
                <DataEl/>
              </li>
              <li>
                {/* <a href="#" target="_blank"  className="to_opened"></a> */}
                <DataEl/>
              </li>
              <li>
                {/* <a href="#" target="_blank" className="to_opened"></a> */}
                <DataEl/>
              </li>
              <li>
                {/* <a href="#" target="_blank" className="to_opened"></a> */}
                <DataEl/>
              </li>
            </ul>
          </div>
        </section>
        <div className="main_body">
          <section id="how-to-buy">
            <div style={{display:"flex"}}>
              <button className='div2-a' onClick={CardDisplay(1)}>{t('pre_s')}</button>
              <button className='div-nth-of-type div2-a' onClick={CardDisplay(2)}>{t('pledge_mining')}</button>
              <button className='div2-a' onClick={CardDisplay(3)}>{t('airdrop')}</button>
            </div>

            <div style={{display: pledge}}>
              <div className="itemdiv1">
                <div className="itemdiv1-title">{t('pre_sale')}</div>
                <div className="itemdiv1-count">
                  <Countdown t={t}/>
                </div>
              </div>
              <div className="itemdiv2">
                <div className="progress-container">
                  <div className="progress-bar" style={{width: progresBar}}></div>
                </div>
              </div>
              <div className="buy-num">{preSaleAmountSent}BGK</div>
              <div>
                <span className='span_shuru'>{t('totalSupply')}</span>
                <div className="input-div">
                  <input placeholder="0" aria-invalid="true" 
                  autoCapitalize="off" autoComplete="off" autoCorrect="off" inputMode="decimal" spellCheck="false" minLength="1" maxLength="11" type="text" value={inputValue}
                  onChange={HandleChange}
                  className="input-num"/>
                  <div className="input-num-div"><button type="button" onClick={SetMax}><span>MAX</span></button></div>
                </div>
                <div className='shou-bgk'>{preSalePrice}BGK</div>
              </div>
              <div className="div-con">
                <table>
                  <tbody>
                    <tr>
                      <td>{t('status')}</td>
                      <td style={{textAlign: "right"}}>{t('coming_soon')}</td>
                    </tr>
                    <tr>
                      <td>{t('sales_type')}</td>
                      <td style={{textAlign: "right"}}>{t('public')}</td>
                    </tr>
                    <tr>
                      <td>{t('min_pur_quan')}</td>
                      <td style={{textAlign: "right"}}>0.2 BNB</td>
                    </tr>
                    <tr>
                      <td>{t('max_pur_quan')}</td>
                      <td style={{textAlign: "right"}}>10 BNB</td>
                    </tr>
                    <tr>
                      <td colSpan="2" className='status_td'>
                        <button onClick={ConnectWallet} style={{display: isVisible ? 'block' : 'none'}}>
                          <span>{account ? acct: t('connect_wallet')}</span>
                        </button>
                        <button onClick={BuyTokenButton} style={{display: isVisible ? 'none' : 'block'}}>
                          <span>{t('enter_an_amount')}</span>
                        </button>
                        {/* <button onClick={BuyTokenButton} style={{display: isVisible ? 'none' : 'block'}} disabled>
                          <span>{t('enter_an_amount')}</span>
                        </button> */}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div style={{display: preSale}}>
              <div className="itemdiv1">
                <div className="itemdiv1-title">{t('get_bgk')}</div>
                <div className='div2'>
                  <button className='div2-a' onClick={SearchSDQ}>{t('pledge_inquiry')}</button>
                  <button className='div2-a' onClick={ExitStake}>{t('release_pledge')}</button>
                </div>
                <div className='pada-num'>
                  <span>{t('pledge_in_pro')}{stakeNum}BGK <br/> {t('pledge_after')}{stakeHours}</span>
                </div>
                <div className='pada-title'>
                  <span>{t('pledge_gbk')}</span>
                </div>
                <div className='width80'>
                  <div className="input-div pad-bottom">
                    <input placeholder="0" aria-invalid="true" 
                    autoCapitalize="off" autoComplete="off" autoCorrect="off" inputMode="decimal" spellCheck="false" minLength="1" maxLength="11" type="text" value={inputPledge}
                    onChange={HandleChange2}
                    className="input-num2"/>
                    <div className="input-num-div2"><button type="button" onClick={HandleClick}><span>MAX</span></button></div>
                  </div>
                </div>
                <div className='pada-title'>
                  <span>{t('earnings')}</span>
                </div>
                <div className='width80'>
                  <div className="input-div pad-bottom">
                    <input placeholder="0" aria-invalid="true" 
                    autoCapitalize="off" autoComplete="off" autoCorrect="off" inputMode="decimal" spellCheck="false" minLength="1" maxLength="11" type="text" value={inputProfit}
                    className="input-num2" readOnly/>
                  </div>
                </div>
                <div className='div2'>
                  <button className='itemdiv1-item-div-a2'  onClick={ConnectWallet} style={{display: isVisible ? 'block' : 'none'}}>
                    <span className="uppercase">{t('connect_wallet')}</span>
                  </button>
                  <button className='itemdiv1-item-div-a2' onClick={ZhiYa} style={{display: isVisible ? 'none' : 'block'}}>
                  <span className="uppercase">{t('pledge')}</span>
                  </button>
                </div>
              </div>
            </div>

            <div style={{display: shortPosition}}>
              <div className="itemdiv1">
                <div className="itemdiv1-title1">
                  <div>{t('reward')}：200BGK</div>
                  <img className='itemdiv1-img' src="/img/button_kong.png" alt="Black Goku"/>
                </div>
                <div className="itemdiv1-title1">
                  <div><img className='itemdiv1-img2' src="/img/sns-1-h.png" alt="Black Goku"/></div>
                  <div className='itemdiv1-itemt'>{t('retweet_like')}</div>
                  <a className='div3-a' href="https://x.com/BlacGoku_" target="_blank">{t('go')}</a>
                </div>
                <div className="itemdiv1-title1">
                  <div><img className='itemdiv1-img2' src="/img/sns-2-h.png" alt="Black Goku"/></div>
                  <div className='itemdiv1-itemt'>{t('join_the_community')}</div>
                  <a className='div3-a' href="https://t.me/+8pBIdAUjSeAzNGQ9" target="_blank">{t('join')}</a>
                </div>
                <div className='itemdiv1-item-div'>
                  <div style={{float: "left",paddingLeft: "2.5vw"}}><span>{t('forwarding_link')}</span></div>
                  <div className='width100'>
                    <input ref={inputTwitterUrl} minLength="1" type="text" className="input-num3" 
                    // value={inputTwitterUrl} onChange={getTwitterUrl}
                    />
                  </div>
                </div>
                <div>
                  <button className='itemdiv1-item-div-a2' onClick={checkKoto}>
                    <span>{t('obtain_airdrop')}</span>
                  </button>
                </div>
              </div>
            </div>
          </section>
          <section id="x_musk">
            <h2>{t('musk_attention')}</h2>
            <div className="left">
              
              <p className="text-white">{t('musk_text')}
                <a className='x_musk_a' target="_blank" href="https://x.com/elonmusk/status/1832194200839823817">{t('go_musk_twitter')} <span><img src="/img/sns-1-h.png"></img></span>
                </a>
              </p>
            </div>
            <img className="about-bitsdog_img" src="/img/x_musk.png"/>
          </section>
          <section id="about-bitsdog">
              <h2>{t('vision')}</h2>
              <p className="text-white">{t('vision_com')}</p>
          </section>
          <section id="tokenomics">
            <div className="supply">
              <h2>{t('tokenomics')}</h2>
              <div className='mics_div'>
                <img className='yq' src="/img/yh.png"></img>
                <table>
                  <tbody>
                    <tr>
                      <td style={{textAlign:"left"}}>{t('token_supply')}</td>
                      <td>21,000,000</td>
                    </tr>
                    <tr>
                      <td style={{textAlign:"left"}}>{t('presale')}</td>
                      <td>35%</td>
                    </tr>
                    <tr>
                      <td style={{textAlign:"left"}}>{t('airdrop')}</td>
                      <td>15%</td>
                    </tr>
                    <tr>
                      <td style={{textAlign:"left"}}>{t('mobility')}</td>
                      <td>25%</td>
                    </tr>
                    <tr>
                      <td style={{textAlign:"left"}}>{t('pledge_reward')}</td>
                      <td>20%</td>
                    </tr>
                    <tr>
                      <td style={{textAlign:"left"}}>{t('community_ecolo')}</td>
                      <td>5%</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </section>
          <section id="support">
            <h2>{t('future_plan')}</h2>
            <p>{t('future_plan_com')}<br/><br/>{t('future_plan_com2')}<br/><br/>{t('future_plan_com3')}<br/><br/>{t('future_plan_com4')}</p>
          </section>
          
          <section id="contact">
            <img src="/img/button_kong.png"/>
            <div>
              <span className="text-[calc(52/1920*100vw)] uppercase text-white">CA:0x9e56d9887a69a94f3a9ce435687018a5581b41b5
                <i onClick={()=>{navigator.clipboard.writeText("0x9e56d9887a69a94f3a9ce435687018a5581b41b5")}}><svg className="cop_svg"
                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 43" fill="none">
                    <path
                      d="M10.3591 8.28979L10.3541 12.0934V30.9134C10.3541 34.1293 12.9611 36.7364 16.177 36.7364L31.6146 36.7369C31.061 38.3023 29.568 39.4239 27.8128 39.4239H16.177C11.4769 39.4239 7.66663 35.6137 7.66663 30.9134V12.0934C7.66663 10.3363 8.79081 8.84176 10.3591 8.28979ZM32.302 3.58301C34.5284 3.58301 36.3333 5.38786 36.3333 7.61426V30.9059C36.3333 33.1322 34.5284 34.9372 32.302 34.9372H16.177C13.9506 34.9372 12.1458 33.1322 12.1458 30.9059V7.61426C12.1458 5.38786 13.9506 3.58301 16.177 3.58301H32.302ZM32.302 6.27051H16.177C15.4349 6.27051 14.8333 6.87213 14.8333 7.61426V30.9059C14.8333 31.648 15.4349 32.2497 16.177 32.2497H32.302C33.0442 32.2497 33.6458 31.648 33.6458 30.9059V7.61426C33.6458 6.87213 33.0442 6.27051 32.302 6.27051Z"
                      fill="white"></path>
                  </svg></i></span>
              <p><span>{t('do_love')}</span></p>
              <ul>
                <li><a href="https://x.com/BlacGoku_" target="_blank">Twitter<span><svg
                        xmlns="http://www.w3.org/2000/svg" width="31" height="30" viewBox="0 0 31 30" fill="none">
                        <path
                          d="M5.91667 0C2.92512 0 0.5 2.42512 0.5 5.41667V24.5833C0.5 27.5748 2.92512 30 5.91667 30H25.0833C28.0748 30 30.5 27.5748 30.5 24.5833V5.41667C30.5 2.42512 28.0748 0 25.0833 0H5.91667ZM3 5.41667C3 3.80583 4.30583 2.5 5.91667 2.5H25.0833C26.6942 2.5 28 3.80583 28 5.41667V24.5833C28 26.1942 26.6942 27.5 25.0833 27.5H5.91667C4.30583 27.5 3 26.1942 3 24.5833V5.41667ZM10.0833 10.8333H17.9842L9.20387 19.5287C8.71333 20.0143 8.70948 20.8058 9.19525 21.2963C9.68103 21.7868 10.4725 21.7907 10.963 21.305L19.6667 12.6857V20.4167C19.6667 21.107 20.2263 21.6667 20.9167 21.6667C21.607 21.6667 22.1667 21.107 22.1667 20.4167V9.58333C22.1667 8.89298 21.607 8.33333 20.9167 8.33333H10.0833C9.39298 8.33333 8.83333 8.89298 8.83333 9.58333C8.83333 10.2737 9.39298 10.8333 10.0833 10.8333Z"
                          fill="#00FFF0"></path>
                      </svg></span></a></li>
                <li><a href="https://t.me/+8pBIdAUjSeAzNGQ9" target="_blank">Telegram<span><svg
                        xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                        <path
                          d="M5.41667 0C2.42512 0 0 2.42512 0 5.41667V24.5833C0 27.5748 2.42512 30 5.41667 30H24.5833C27.5748 30 30 27.5748 30 24.5833V5.41667C30 2.42512 27.5748 0 24.5833 0H5.41667ZM2.5 5.41667C2.5 3.80583 3.80583 2.5 5.41667 2.5H24.5833C26.1942 2.5 27.5 3.80583 27.5 5.41667V24.5833C27.5 26.1942 26.1942 27.5 24.5833 27.5H5.41667C3.80583 27.5 2.5 26.1942 2.5 24.5833V5.41667ZM9.58333 10.8333H17.4842L8.70387 19.5287C8.21333 20.0143 8.20948 20.8058 8.69525 21.2963C9.18103 21.7868 9.97248 21.7907 10.463 21.305L19.1667 12.6857V20.4167C19.1667 21.107 19.7263 21.6667 20.4167 21.6667C21.107 21.6667 21.6667 21.107 21.6667 20.4167V9.58333C21.6667 8.89298 21.107 8.33333 20.4167 8.33333H9.58333C8.89298 8.33333 8.33333 8.89298 8.33333 9.58333C8.33333 10.2737 8.89298 10.8333 9.58333 10.8333Z"
                          fill="#00FFF0"></path>
                      </svg></span></a></li>
              </ul>
            </div>
          </section>
          <section id="footer"><span>©Copyright 2024,$BitsDog Community</span><span>All Right Reserved.</span>
          </section>
      </div>
    </div>
  );
}

export default App;
