import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from './en.json';
import cn from './cn.json';
 
const resources = {
    en: {
        translation: en
    },
    cn:{
        translation:cn
    }
};
 
i18n
    .use(LanguageDetector)
    .use(initReactI18next).init({
    resources,
    fallbackLng: 'en',
 
    interpolation: {
        escapeValue: false
    }
});
 
export default i18n;